<template>
    <div class="school-admin">
        <admin-template v-bind="templateConfig"></admin-template>
    </div>
</template>

<script>
import AdminTemplate from "components/admin/educationalManage/AdminTemplate";
import { schoolAdminUploadLogo, addSchoolAdmin, editSchoolAdmin, getSchoolAdminListData, deleteSchoolAdmin } from "@/utils/apis";

export default {
    name: "SchoolAdmin",
    data() {
        return {
            templateConfig: {
                origin: "学校管理员",
                uploadImgPath: "school_user/upload",
                addAdmin: addSchoolAdmin,
                editAdmin: editSchoolAdmin,
                getAdminListData: getSchoolAdminListData,
                deleteAdmin: deleteSchoolAdmin,
            },
        };
    },
    components: {
        AdminTemplate,
    },
    methods: {},
    mounted() {},
};
</script>

<style scoped lang="scss">
.school-admin {
    height: 100%;
}
</style>
