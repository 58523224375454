<template>
    <div class="admin-template" v-if="origin">
        <table-page-template
            v-bind="templateConfig"
            :paging-config.sync="getAdminListParams"
            :total-count="totalCount"
            @page-change="getAdminList"
        >
            <template #tableOperate="{ item }">
                <el-link type="primary" :underline="false" @click="edit(item)">编辑</el-link>
                <el-link type="danger" :underline="false" @click="del(item)">删除</el-link>
            </template>
        </table-page-template>
        <my-dialog
            class="operate-admin"
            :title="operateDialogTitle"
            :is-show.sync="operateDialog"
            :confirm-handler="confirmOperate"
            @closeDialog="clearFormData"
            :top="40"
            padding="40px 0 18px"
        >
            <div class="form-box">
                <pack-el-form
                    ref="elForm"
                    :rules="rules"
                    :params.sync="operateParams"
                    :form-config="formConfig"
                    label-width="80"
                ></pack-el-form>
            </div>
        </my-dialog>
        <my-dialog class="delete-admin" :title="`删除${origin}`" :is-show.sync="deleteDialog" :confirm-handler="confirmDelete">
            <div class="content">是否删除此{{ origin }}, 删除后将无法恢复</div>
        </my-dialog>
    </div>
</template>

<script>
import TablePageTemplate from "components/common/TablePageTemplate";
import PackElForm from "components/common/PackElForm";
import MyDialog from "components/common/MyDialog";
import { getSchoolListData } from "@/utils/apis";
export default {
    name: "SchoolAdmin",
    data() {
        return {
            tableData: [],
            getAdminListParams: {
                page: 1,
                limit: 10,
            },
            operateParams: {
                username: "",
                nickname: "",
                school_id: "",
                avatar: "",
                password: "",
                // id: -1,
            },
            rules: {
                nickname: [
                    { required: true, message: "请输入管理员名称", trigger: "blur" },
                    { min: 2, message: "管理员名称的长度不能小于2", trigger: "blur" },
                ],
                username: [
                    { required: true, message: "请输入账号", trigger: "blur" },
                    { min: 4, message: "账号的长度不能小于4", trigger: "blur" },
                ],
                school_id: [{ required: true, message: "请选择所属学校", trigger: "change" }],
                password: [{ min: 6, message: "密码的长度不能小于6", trigger: "blur" }],
            },
            totalCount: 1,
            operateDialogTitle: "",
            schoolSelectList: [],
            deleteId: -1,
            operateCallback: null,
            //dialog
            deleteDialog: false,
            operateDialog: false,
        };
    },
    components: {
        TablePageTemplate,
        MyDialog,
        PackElForm,
    },
    props: {
        origin: {
            type: String,
            default: "",
        },
        uploadImgPath: {
            type: String,
            default: "",
        },
        addAdmin: {
            type: Function,
            default: function () {},
        },
        editAdmin: {
            type: Function,
            default: function () {},
        },
        getAdminListData: {
            type: Function,
            default: function () {},
        },
        deleteAdmin: {
            type: Function,
            default: function () {},
        },
    },
    computed: {
        host() {
            let host = localStorage.getItem("hosturl");
            return !host ? location.origin : host;
        },
        templateConfig() {
            return {
                tableData: this.tableData,
                rightConfig: {
                    text: `创建${this.origin}`,
                    handler: this.add,
                },
                tableConfig: [
                    { label: `${this.origin}名称`, prop: "nickname" },
                    { label: "账号", prop: "username", align: "center" },
                    { label: "LOGO", prop: "avatar", isImg: true, align: "center" },
                    { label: "学校名称", prop: "name", align: "center" },
                    { label: "操作", width: 300, align: "center" },
                ],
            };
        },
        formConfig() {
            return [
                {
                    label: "管理员名称",
                    prop: "nickname",
                    placeholder: "2-20个字符",
                    max: 20,
                },
                {
                    label: "账号",
                    prop: "username",
                    placeholder: "4-20个字符",
                    max: 20,
                },
                {
                    label: "密码",
                    prop: "password",
                    placeholder: "6-20个字符",
                    max: 20,
                },
                {
                    label: "所属学校",
                    prop: "school_id",
                    type: "select",
                    placeholder: "请选择学校",
                    options: this.schoolSelectList,
                },
                {
                    label: "头像",
                    prop: "avatar",
                    type: "img",
                    uploadPath: this.uploadImgPath,
                    clear: this.clearAvatar,
                    uploadSuccess: this.uploadSuccess,
                    uploadErr: this.uploadErr,
                },
            ];
        },
    },
    methods: {
        init() {
            this.getAdminList();
            this.getSchoolList();
        },
        async getAdminList() {
            let res = await this.getAdminListData(this.getAdminListParams);
            this.tableData = res.data.list;
            this.totalCount = res.data.total;
        },
        async getSchoolList() {
            let res = await getSchoolListData({ page: 1, limit: "all" });
            this.schoolSelectList = res.data.map((item) => ({ label: item.name, value: item.id }));
        },
        edit(item) {
            this.operateDialogTitle = `编辑${this.origin}`;
            let school = this.schoolSelectList.find((schoolItem) => schoolItem.label == item.name);
            Object.assign(this.operateParams, item);
            this.operateParams.school_id = school ? school.value : "";
            this.operateCallback = this.editAdmin;
            this.operateDialog = true;
        },
        add() {
            this.$delete(this.operateParams, "id");
            this.operateDialogTitle = `创建${this.origin}`;
            this.operateCallback = this.addAdmin;
            this.rules.password.push({ required: true, message: "请输入密码", trigger: "blur" });
            this.operateDialog = true;
        },
        async confirmOperate() {
            this.$refs.elForm.validate(async () => {
                let avatar = this.operateParams.avatar ? this.operateParams.avatar.replace(this.host, "") : "";
                let res = await this.operateCallback({
                    ...this.operateParams,
                    avatar,
                });
                this.operateDialog = false;
                this.getAdminList();
                this.$message.success(res.msg);
            });
        },
        del(item) {
            this.deleteId = item.id;
            this.deleteDialog = true;
        },
        async confirmDelete() {
            let res = await this.deleteAdmin({ id: this.deleteId });
            this.getAdminList();
            this.deleteDialog = false;
            this.$message.success(res.msg);
        },
        clearFormData() {
            Object.keys(this.operateParams).forEach((item) => {
                this.operateParams[item] = typeof item == "string" ? "" : -1;
            });
            this.$delete(this.rules.password, 1);
        },
        clearAvatar() {
            this.operateParams.avatar = "";
        },
        uploadSuccess(res, file, fileList) {
            this.operateParams.avatar = res.data.src;
            this.$message.success(res.msg);
        },
        uploadErr(err, file, fileList) {
            this.$message.error("上传失败, 请重新上传");
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style scoped lang="scss">
.admin-template {
    box-sizing: border-box;
    height: 100%;
    padding: 20px 20px 0;
}
</style>
